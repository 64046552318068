<template>
  <div class="selfemployed">
      <p class="title">卖家个体户入驻 - 基本信息</p>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less">
.selfemployed {
    max-width: 1440px;
    min-width: 990px;
    margin: 60px auto;
    background: #ffffff;
    padding: 20px 28px;
    .title {
        font-family: 'PingFang SC ', 'PingFang SC', sans-serif;
        font-weight: 650;
        font-style: normal;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 28px;
        margin-bottom: 20px;
    }
}
</style>